import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppConfig } from "../../AppConfig";
import { StoreState } from "../../store/rootReducer";
import { addUserDefinedScale } from "../../store/scaleSlice";
import { isNumber } from "../../utils/helpers";
import InputField from "../genericComponents/InputField/InputField";
import NfsButton from "../genericComponents/NfsButton/NfsButton";
import "./AddScale.css";
import checkIcon from "../../assets/scale/check-24.svg";
import xIcon from "../../assets/scale/x-24.svg";

interface AddScaleProps {
    toggleAddScaleVisible: () => void;
    setScale: (option: string | null) => void;
}

const AddScale = (props: AddScaleProps) => {
    const { toggleAddScaleVisible, setScale } = props;

    const [newScaleValue, setNewScaleValue] = useState<string>("");
    const userDefinedScales = useSelector((state: StoreState) => state.scale.userDefinedScales);
    const [addingDisabled, setAddingDisabled] = useState<boolean>(true);
    const dispatch = useDispatch();

    const viewLoaded = useSelector((state: StoreState) => state.app.viewLoaded);

    const mapView = document.querySelector("arcgis-map")?.view;

    const disabledCondition = (value: string) => {
        return (
            !isNumber(value) ||
            !viewLoaded ||
            !mapView ||
            parseInt(value) > AppConfig.Scale.MinScale ||
            parseInt(value) < AppConfig.Scale.MaxScale ||
            [...userDefinedScales, ...AppConfig.Scale.Scales].includes(parseInt(value))
        );
    };

    useEffect(() => {
        if (!mapView) return;

        const scale = mapView.scale.toFixed(0);
        setNewScaleValue(scale);
        setAddingDisabled(disabledCondition(scale));
    }, [mapView?.scale]);

    return (
        <div className="add-scale flex-row">
            <label htmlFor="add-scale" className="add-scale-label">
                1:
            </label>
            <InputField
                inputFieldId="add-scale"
                label=""
                value={newScaleValue}
                type="number"
                onChange={(value) => {
                    setAddingDisabled(disabledCondition(value));
                    setNewScaleValue(value);
                }}
            />
            <NfsButton
                disabled={addingDisabled}
                onClick={() => {
                    toggleAddScaleVisible();
                    if (disabledCondition(newScaleValue)) return;

                    dispatch(addUserDefinedScale(parseInt(newScaleValue)));
                    localStorage.setItem("userDefinedScales", JSON.stringify([...userDefinedScales, newScaleValue]));
                    setScale(newScaleValue);
                }}
                icon={checkIcon}
            />
            <NfsButton
                onClick={() => {
                    toggleAddScaleVisible();
                }}
                icon={xIcon}
            />
        </div>
    );
};

export default AddScale;
