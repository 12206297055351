import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../store/rootReducer';
import { toggleDarkmode } from '../../store/appSlice';
import SunIcon from "../../assets/sun.svg?react";
import MoonIcon from "../../assets/moon.svg?react";

import "./DarkModeToggle.css";

const DarkModeToggle = () => {
    const dispatch = useDispatch();
    const darkmode = useSelector((state: StoreState) => state.app.darkmode);

    const handleToggle = () => {
        dispatch(toggleDarkmode());

        const body = document.body;
        if (body) {
            body.classList.toggle('dark', !darkmode);
            body.classList.toggle('light', darkmode);
        }
    };

    return (
        <button className='dark-mode-button' onClick={handleToggle}>
            {darkmode ? <SunIcon /> : <MoonIcon />}
        </button>
    );
};

export default DarkModeToggle;