import { useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import { useDispatch } from "react-redux";

import Accessor from "@arcgis/core/core/Accessor.js";
import MapView from "@arcgis/core/views/MapView";
import AreaMeasurement2DViewModel from "@arcgis/core/widgets/AreaMeasurement2D/AreaMeasurement2DViewModel.js";
import DistanceMeasurement2DViewModel from "@arcgis/core/widgets/DistanceMeasurement2D/DistanceMeasurement2DViewModel.js";

import { setMeasureToolOpen } from "../../../store/appSlice";
import NfsButton from "../../genericComponents/NfsButton/NfsButton";
import "./MeasurementControl.css";

import * as reactiveUtils from "@arcgis/core/core/reactiveUtils.js";
import Graphic from "@arcgis/core/Graphic";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import TextSymbol from "@arcgis/core/symbols/TextSymbol.js";
import Draw from "@arcgis/core/views/draw/Draw";
import CloseIcon from "../../../assets/close.svg?react";
import AreaIcon from "../../../assets/measurementControl/area.svg?react";
import LineIcon from "../../../assets/measurementControl/line.svg?react";
import PeilelinjalIcon from "../../../assets/measurementControl/peilelinjal.svg?react";
import PreviousIcon from "../../../assets/measurementControl/previous.svg?react";
import ResetIcon from "../../../assets/measurementControl/reset.svg?react";
import { getMapView } from "../../../utils/arcgisUtils";
import NfsDropdown from "../../genericComponents/NfsDropdown/NfsDropdown";
import RenderInWindow from "../../RenderInWindow/RenderInWindow";
import { MeasureType, Unit } from "./measurementEnums";
import { drawPeilelinjal } from "./PeilelinjalGraphics";

const MeasurementControl = () => {
    const dispatch = useDispatch();

    const measureToolRef = useRef(null);

    const [prevMeasures, setPrevMeasures] = useState<Array<any>>([]);

    const measurementVMRef = useRef<DistanceMeasurement2DViewModel | AreaMeasurement2DViewModel | null>(null);

    const [distanceActive, setDistanceActive] = useState<boolean>(false);
    const [areaActive, setAreaActive] = useState<boolean>(false);
    const [peilelinjalActive, setPeilelinjalActive] = useState<boolean>(false);
    const [measurementVM, setMeasurementVM] = useState<
        DistanceMeasurement2DViewModel | AreaMeasurement2DViewModel | null
    >(null);
    const [currentMeasures, setCurrentMeasures] = useState<Array<{ key: string; value: string }>>([]);
    const [unit, setUnit] = useState<string | null>(Unit.Meter);
    const [popout, setPopout] = useState<boolean>(false);
    const [peilelinjalDraw, setPeilelinjalDraw] = useState<Draw | null>(null);

    // Need this ref in order to destroy the draw object when the component unmounts
    const peilelinjalDrawRef = useRef<Draw | null>(peilelinjalDraw);
    const prevMeasuresRef = useRef<Array<any>>(prevMeasures);

    useEffect(() => {
        return () => {
            const mapview = getMapView();
            if (!mapview) return;

            removePeilelinjal(mapview);

            closeMeasureControl();
        };
    }, []);

    useEffect(() => {
        peilelinjalDrawRef.current = peilelinjalDraw;
    }, [peilelinjalDraw]);

    useEffect(() => {
        prevMeasuresRef.current = prevMeasures;
    }, [prevMeasures]);

    const closeMeasureControl = () => {
        if (measurementVM) {
            measurementVM?.clear();
            setMeasurementVM(null);
            setDistanceActive(false);
            setAreaActive(false);
        }

        setPeilelinjalActive(false);

        prevMeasuresRef.current?.forEach((m) => {
            m?.vm?.clear();
            m?.vm?.destroy();
        });
        setPrevMeasures([]);

        setCurrentMeasures([]);

        if (measurementVMRef.current) {
            measurementVMRef.current?.clear();
            // @ts-expect-error
            measurementVMRef.current?.destroy();
            measurementVMRef.current = null;
        }
    };

    const watchMeasureState = (measurement: any) => {
        measurement.watch("state", (newValue: "disabled" | "ready" | "measuring" | "measured") => {
            console.log("state", newValue);
            if (newValue === "measured") {
                setDistanceActive(false);
                setAreaActive(false);
                setPeilelinjalActive(false);

                setPrevMeasures([...prevMeasures, { vm: measurement }]);
            }
        });
    };

    const watchMeasureResult = (measurement: DistanceMeasurement2DViewModel | AreaMeasurement2DViewModel) => {
        if (measurement instanceof DistanceMeasurement2DViewModel) {
            (measurement as Accessor).watch("measurementLabel", (newValue: any) => {
                if (!newValue) return;
                setCurrentMeasures([{ key: "Lengde", value: newValue }]);
            });
            return;
        }

        if (measurement instanceof AreaMeasurement2DViewModel) {
            // this VM doesn't know it inherits Accessor...
            let mVM: any = measurement;
            mVM.watch("measurementLabel", (newValue: any) => {
                if (!newValue) return;
                setCurrentMeasures([
                    { key: "Areal", value: newValue?.area },
                    { key: "Omkrets", value: newValue?.perimeter },
                ]);
            });
        }
    };

    const startDrawingPeilelinjal = (mapview: MapView) => {
        if (!measurementVM?.measurement || measurementVM?.state === "measuring") {
            measurementVM?.clear();
        }
        setMeasurementVM(null);

        const draw = new Draw({
            view: mapview,
        });
        setPeilelinjalDraw(draw);
        let action = draw.create("polyline", { mode: "click" });

        // fires when a vertex is added
        action.on("vertex-add", function (evt) {
            drawPeilelinjal(evt.vertices, setCurrentMeasures, mapview, unit);
            if (evt.vertices.length === 2) {
                draw.complete();
            }
        });

        // fires when the pointer moves
        action.on("cursor-update", function (evt) {
            drawPeilelinjal(evt.vertices, setCurrentMeasures, mapview, unit);
        });

        // fires when the drawing is completed
        action.on("draw-complete", function (evt) {
            drawPeilelinjal(evt.vertices, setCurrentMeasures, mapview, unit);
            const peilelinjalLayer = mapview.map.findLayerById("peilelinjal") as GraphicsLayer;
            if (!peilelinjalLayer) return;
            peilelinjalLayer.addMany(mapview.graphics.toArray());

            setPrevMeasures([...prevMeasures, mapview.graphics.toArray()]);
            mapview.graphics.removeAll();
            setPeilelinjalActive(false);
        });
    };

    const startDrawingLengthOrArea = (mapview: MapView, type: MeasureType.Distance | MeasureType.Area) => {
        peilelinjalDraw?.destroy();
        mapview.graphics.removeAll();
        setPeilelinjalDraw(null);
        peilelinjalDrawRef.current = null;

        // create measure viewmodel
        let measurement: DistanceMeasurement2DViewModel | AreaMeasurement2DViewModel | null = null;

        if (mapview instanceof MapView) {
            measurement =
                type === MeasureType.Distance ? new DistanceMeasurement2DViewModel() : new AreaMeasurement2DViewModel();
            measurement.view = mapview;
        }

        if (measurement) {
            if (unit === Unit.Nautiskemil) {
                measurement.unit = "nautical-miles";
            } else {
                measurement.unit = "meters";
            }

            if (type === MeasureType.Distance) {
                // Temporary fix to disable snapping
                reactiveUtils.when(
                    //@ts-expect-error
                    () => measurement.tool,
                    (newValue: any) => {
                        newValue.snappingOptions.enabled = false;
                    }
                );
            }

            watchMeasureResult(measurement);
            watchMeasureState(measurement);

            measurement.start();
            measurementVMRef.current = measurement;
            setMeasurementVM(measurement);
        }
    };

    const toggleMeasurementTool = (type: MeasureType.Distance | MeasureType.Area | MeasureType.Peilelinjal) => {
        const mapview = getMapView();
        if (!mapview) return;

        if (measurementVM?.state === "measuring") {
            if ((distanceActive && type === MeasureType.Distance) || (areaActive && type === MeasureType.Area)) {
                console.log("returning");
                return;
            }
        }

        // finish current measure if any
        resetCurrentMeasure(false);

        // toggle button states
        setDistanceActive(type === MeasureType.Distance);
        setAreaActive(type === MeasureType.Area);
        setPeilelinjalActive(type === MeasureType.Peilelinjal);

        if (type === MeasureType.Peilelinjal) {
            startDrawingPeilelinjal(mapview);
        } else {
            startDrawingLengthOrArea(mapview, type);
        }
    };

    const removePeilelinjal = (mapview: MapView) => {
        peilelinjalDraw?.destroy();
        peilelinjalDrawRef.current?.destroy();
        setPeilelinjalDraw(null);

        const peilelinjalLayer = mapview.map.findLayerById("peilelinjal") as GraphicsLayer;
        if (!peilelinjalLayer) return;

        peilelinjalLayer.removeAll();
        mapview.graphics.removeAll();
    };

    const resetCurrentMeasure = (restart: boolean) => {
        const mapview = getMapView();
        if (!mapview) return;

        setAreaActive(false);
        setDistanceActive(false);
        setPeilelinjalActive(false);
        setCurrentMeasures([]);

        if (restart) {
            prevMeasures.forEach((m) => {
                m?.vm?.clear();
                m?.vm?.destroy();
            });

            removePeilelinjal(mapview);
            setPrevMeasures([]);
        }
    };

    const undoPrevMeasure = () => {
        const mapview = getMapView();
        if (!mapview) return;
        if (prevMeasures.length === 0) return;

        const last = prevMeasures[prevMeasures.length - 1];
        setPrevMeasures(prevMeasures.slice(0, -1));
        setCurrentMeasures([]);
        if (last instanceof Array) {
            const peilelinjalLayer = mapview.map.findLayerById("peilelinjal") as GraphicsLayer;
            if (peilelinjalLayer) {
                peilelinjalLayer.graphics.filter((g) => last.includes(g)).forEach((g) => peilelinjalLayer.remove(g));
            }
        } else {
            last?.vm?.clear();
        }
    };

    const closeTool = () => {
        const mapview = getMapView();
        if (!mapview) return;

        removePeilelinjal(mapview);
        setPeilelinjalActive(false);
        dispatch(setMeasureToolOpen(false));
        resetCurrentMeasure(true);
    };

    useEffect(() => {
        if (unit === Unit.Nautiskemil) {
            if (measurementVM) {
                measurementVM.unit = "nautical-miles";
            }
            prevMeasures.forEach((m) => {
                if (m.vm) {
                    m.vm.unit = "nautical-miles";
                }
            });
        } else {
            if (measurementVM) {
                measurementVM.unit = "meters";
            }
            prevMeasures.forEach((m) => {
                if (m.vm) {
                    m.vm.unit = "meters";
                }
            });
        }

        const mapview = getMapView();
        if (!mapview) return;

        const peilelinjalLayer = mapview.map.findLayerById("peilelinjal") as GraphicsLayer;
        if (!peilelinjalLayer) return;

        const graphicsToAdd: Array<Graphic> = [];
        const graphicsToRemove: Array<Graphic> = [];

        peilelinjalLayer.graphics.forEach((graphic) => {
            if (graphic.attributes?.type !== "lengthLabel") return;

            const newGraphic = graphic.clone();

            if (unit === Unit.Meter) {
                (newGraphic.symbol as TextSymbol).text =
                    graphic.attributes.lengthMeters < 1000
                        ? `${graphic.attributes.lengthMeters.toFixed(2)} m`
                        : `${(graphic.attributes.lengthMeters / 1000).toFixed(2)} km`;
            } else if (unit === Unit.Nautiskemil) {
                (newGraphic.symbol as TextSymbol).text = `${graphic.attributes.lengthNauticalMiles.toFixed(2)} nm`;
            }

            graphicsToAdd.push(newGraphic);
            graphicsToRemove.push(graphic);

            prevMeasures.forEach((m) => {
                if (m instanceof Array && m.includes(graphic)) {
                    const index = m.indexOf(graphic);
                    m[index] = newGraphic;
                }
            });
        });

        peilelinjalLayer.addMany(graphicsToAdd);
        peilelinjalLayer.removeMany(graphicsToRemove);

        if (!measurementVM && prevMeasures.length > 0) {
            const newCurrentMeasures = [...currentMeasures];

            for (const element of newCurrentMeasures) {
                if (element.key === "Lengde") {
                    element.value = (graphicsToAdd[graphicsToAdd.length - 1].symbol as TextSymbol).text;
                    break;
                }
            }

            setCurrentMeasures(newCurrentMeasures);
        }
    }, [unit]);

    return (
        <RenderInWindow disabled={!popout} onClose={closeTool}>
            <Draggable disabled={popout} bounds=".map-container" nodeRef={measureToolRef}>
                <div ref={measureToolRef} className="measurement-container">
                    <div className="measurement-header">
                        <div className="measurement-tools">
                            <NfsButton
                                selected={distanceActive ?? false}
                                outlined
                                onClick={() => toggleMeasurementTool(MeasureType.Distance)}
                            >
                                <LineIcon />
                                Linje
                            </NfsButton>
                            <NfsButton
                                selected={areaActive ?? false}
                                outlined
                                onClick={() => toggleMeasurementTool(MeasureType.Area)}
                            >
                                <AreaIcon />
                                Areal
                            </NfsButton>
                            <NfsButton
                                selected={peilelinjalActive ?? false}
                                outlined
                                onClick={() => toggleMeasurementTool(MeasureType.Peilelinjal)}
                            >
                                <PeilelinjalIcon />
                                Peilelinjal
                            </NfsButton>
                        </div>
                        <div className="flex-row">
                            {/* invisible test/demo button temporary */}
                            <button
                                className="empty-button"
                                style={{ cursor: "default", width: "40px" }}
                                onDoubleClick={() => setPopout(!popout)}
                            />
                            <NfsButton className="empty" onClick={closeTool}>
                                <CloseIcon />
                            </NfsButton>
                        </div>
                    </div>
                    <div className="measurement-content">
                        {!distanceActive && !areaActive && !peilelinjalActive && prevMeasures.length === 0 && (
                            <span className="no-measurement">Velg målemetode</span>
                        )}
                        {(distanceActive || areaActive || peilelinjalActive || prevMeasures.length > 0) && (
                            <>
                                <span className="measurement-instructions">
                                    {distanceActive || areaActive
                                        ? "Klikk for å sette målepunkter i kartet. Dobbeltklikk for å avslutte måling."
                                        : "Klikk for å sette startpunkt. Klikk igjen for å sette endepunkt. Gjenta prosessen for å lage flere peilelinjaler."}
                                </span>
                                <div className="measurement-result">
                                    <span>
                                        {currentMeasures.map((kvp) => {
                                            return (
                                                <div key={kvp.key}>
                                                    <span className="small-paragraph">{kvp.key}: </span>
                                                    <span className="small-paragraph-bold">{kvp.value}</span>
                                                </div>
                                            );
                                        })}
                                    </span>
                                    <div className="flex-row center small-gap">
                                        <span className="small-paragraph">Enhet:</span>
                                        <NfsDropdown
                                            options={[Unit.Meter, Unit.Nautiskemil]}
                                            selectedOption={unit}
                                            setSelectedOption={setUnit}
                                            placeholder=""
                                        />
                                    </div>
                                </div>
                                <div className="flex-row space-between">
                                    {/* <span>
                                    <input type="checkbox" checked={showMoh} onChange={() => setShowMoh(!showMoh)} id="moh" name="moh" />
                                    <label htmlFor="moh">Vis moh</label>
                                    </span> */}
                                    <span></span>
                                    <span className="flex-row small-gap">
                                        <NfsButton
                                            className="empty"
                                            onClick={() => undoPrevMeasure()}
                                            disabled={prevMeasures.length < 1}
                                        >
                                            <PreviousIcon />
                                        </NfsButton>
                                        <NfsButton className="empty" onClick={() => resetCurrentMeasure(true)}>
                                            <ResetIcon />
                                        </NfsButton>
                                    </span>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </Draggable>
        </RenderInWindow>
    );
};

export default MeasurementControl;
