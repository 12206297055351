import "./InputField.css";

interface InputFieldProps {
    inputFieldId: string;
    label: string;
    value: string;
    type: string;
    onChange: (value: string) => void;
    readonly?: boolean;
}

const InputField = (props: InputFieldProps) => {
    return (
        <label htmlFor={props.inputFieldId} className="input-field-label">
            {props.label}
            <div className={`input-field-container ${props.readonly ? "readonly" : ""}`}>
                <input
                    id={props.inputFieldId}
                    readOnly={props.readonly}
                    onChange={(event) => props.onChange(event.target.value)}
                    value={props.value}
                    className="input-field"
                    type={props.type}
                />
            </div>
        </label>
    );
};

export default InputField;
