import { createSlice } from "@reduxjs/toolkit";

interface AppState {
  darkmode: boolean;
  measureToolOpen: boolean;
  layerListOpen: boolean;
  viewLoaded: boolean;
  featureViewerOpen: boolean;
}

const initialState: AppState = {
  darkmode: false,
  measureToolOpen: false,
  layerListOpen: false,
  viewLoaded: false,
  featureViewerOpen: false,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    toggleDarkmode(state) {
      state.darkmode = !state.darkmode;
    },
    setMeasureToolOpen(state, action) {
      state.measureToolOpen = action.payload;
    },
    setLayerListOpen(state, action) {
      state.layerListOpen = action.payload;
    },
    setViewLoaded(state) {
      state.viewLoaded = true;
    },
    setFeatureViewerOpen(state, action) {
      state.featureViewerOpen = action.payload;
    },
  },
});

export default appSlice.reducer;
export const {
  toggleDarkmode, setViewLoaded,
  setMeasureToolOpen,
  setLayerListOpen,
  setFeatureViewerOpen,
} = appSlice.actions;
export type { AppState };
