export const readOnlyFields = [
    "OBJECTID",
    "GISOBJID",
    "OppdatertDato",
    "OppdatertAv",
    "Lat",
    "Long",
    "OpprettetDato",
    "MaltDato",
    "MaltAv",
    "gyldigFra",
    "gyldigTil",
]