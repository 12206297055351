export enum MeasureType {
    Distance = "distance",
    Area = "area",
    Peilelinjal = "peilelinjal",
}

export enum Unit {
    Meter = "meter",
    Nautiskemil = "nautiske mil",
}
