import { ArcgisMapCustomEvent } from "@arcgis/map-components";
import {
    ArcgisEditor,
    ArcgisExpand,
    ArcgisLayerList,
    ArcgisLegend,
    ArcgisLocate,
    ArcgisMap,
    ArcgisMeasurement,
    ArcgisPlacement,
    ArcgisScaleBar,
    ArcgisZoom,
} from "@arcgis/map-components-react";

import * as reactiveUtils from "@arcgis/core/core/reactiveUtils";
import Point from "@arcgis/core/geometry/Point";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import PictureMarkerSymbol from "@arcgis/core/symbols/PictureMarkerSymbol.js";
import { useContext, useEffect, useRef, useState } from "react";
import { AppConfig } from "../../AppConfig";
import boatIcon from "../../assets/boat.svg";

import Basemap from "@arcgis/core/Basemap";
import Graphic from "@arcgis/core/Graphic";

import WebMap from "@arcgis/core/WebMap";
import Popup from "@arcgis/core/widgets/Popup.js";
import { AppContext, UserContext, VersionContext } from "../../Context";
import { NmeaData } from "../../types/types";
import Connector from "../../utils/signalr";
import { PopupPortal } from "../popups/PopupPortal";
import { SeamarkPopup } from "../popups/SeamarkPopup";
import RenderInWindow from "../RenderInWindow/RenderInWindow";
import { uuidv4 } from "../../utils/helpers";
import Slider from "@arcgis/core/widgets/Slider.js";
import LayerListItem from "@arcgis/core/widgets/LayerList/ListItem.js";
import LayerListItemPanel from "@arcgis/core/widgets/LayerList/ListItemPanel.js";
import * as intl from "@arcgis/core/intl.js";

import VectorTileLayer from "@arcgis/core/layers/VectorTileLayer";
import NfsButton from "../genericComponents/NfsButton/NfsButton";

import RulerIcon from "../../assets/ruler.svg?react";
import LayerListIcon from "../../assets/layerlist.svg?react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../store/rootReducer";
import { setViewLoaded, setLayerListOpen, setMeasureToolOpen, setFeatureViewerOpen } from "../../store/appSlice";

import Scale from "../Scale/Scale";
import { VersionManagement } from "../VersionManagement/VersionManagement";
import "./MapComponent.css";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";

const popupRoot = document.createElement("div");

const MapControl = () => {
    const context = useContext(VersionContext);
    const versionContext = useContext(VersionContext);

    const userContext = useContext(UserContext);
    const userName = userContext?.user.value?.email;

    const appContext = useContext(AppContext);

    const arcgisMapRef = useRef<HTMLArcgisMapElement | null>(null);

    const measurementToolOpen = useSelector((state: StoreState) => state.app.measureToolOpen);
    const layerListOpen = useSelector((state: StoreState) => state.app.layerListOpen);

    const esriHandleRef = useRef<IHandle[]>([]);

    const dispatch = useDispatch();

    const { connection } = Connector(userName ?? uuidv4());

    useEffect(() => {
        connection.on("ReceiveMessage", handleReceiveMessage);

        return () => {
            connection.off("ReceiveMessage", handleReceiveMessage);
        };

        function handleReceiveMessage(_sourceClientID: string, messageType: string, message: string) {
            if (messageType === "LocationUpdate") {
                const locationInfo: NmeaData = JSON.parse(message);
                drawBoatInMap(locationInfo);
            }
        }
    }, [connection]);

    const handleViewReady = (event: ArcgisMapCustomEvent<void>) => {
        arcgisMapRef.current = event.target;

        const view = event.target.view;
        context?.mapView.set(view);

        view.goTo({
            center: [5.75, 58.97], // Longitude, latitude
            scale: AppConfig.Scale.DefaultScale,
        });

        esriHandleRef.current.push(
            reactiveUtils.when(
                () => view.scale,
                (value) => {
                    if (value < AppConfig.Scale.MaxScale) {
                        view.scale = AppConfig.Scale.MaxScale;
                    } else if (value > AppConfig.Scale.MinScale) {
                        view.scale = AppConfig.Scale.MinScale;
                    }
                }
            )
        );

        esriHandleRef.current.push(
            reactiveUtils.on(
                () => view.popup?.viewModel,
                "trigger-action",
                (event) => {
                    // If the zoom-out action is clicked, fire the zoomOut() function
                    if (event.action.id === "open") {
                        appContext?.selectedFeature.set(view.popup?.selectedFeature ?? null);
                        dispatch(setFeatureViewerOpen(true));
                    }
                }
            )
        );

        dispatch(setViewLoaded());
    };

    // const [layers, setLayers] = useState<__esri.Layer[]>([]);
    const [webMap, setWebMap] = useState<__esri.WebMap>();
    const [_layerVersion, setLayerVersion] = useState<string | null>(null);

    const drawBoatInMap = (locationInfo: NmeaData) => {
        if (arcgisMapRef.current && locationInfo.Latitude && locationInfo.Longitude) {
            const view = arcgisMapRef.current.view;
            view.graphics.removeAll();
            const point = new Point({
                longitude: locationInfo.Longitude,
                latitude: locationInfo.Latitude,
                spatialReference: { wkid: 4326 },
            });
            const boatSymbol = new PictureMarkerSymbol({
                url: boatIcon,
                width: "32px",
                height: "32px",
                angle: locationInfo.Heading ?? 0,
            });
            const dateString = new Date().toLocaleString();
            const pointGraphic = new Graphic({
                geometry: point,
                symbol: boatSymbol,
                popupTemplate: {
                    title: "Båt",
                    content: `Sist oppdatert ${dateString} <br> Fart: ${locationInfo.Speed ?? "ikke oppgitt"
                        } knop <br> Kurs: ${locationInfo.Heading ?? "ikke oppgitt"} grader <br> Horisontal presisjon: ${locationInfo.HorizontalPrecision ?? "ikke oppgitt"
                        } meter <br> Høyde: ${locationInfo.Altitude ?? "ikke oppgitt"} meter <br> Antall satellitter: ${locationInfo.NumberOfSatellites ?? "ikke oppgitt"
                        }`,
                },
            });
            view.graphics.add(pointGraphic);
        }
    };

    const layerListRenderFunction = async function (event: any) {
        const item = event.item as LayerListItem;
        if (item.layer) {
            // Adds a slider for updating a group layer's opacity
            let slider: any | null = null;
            const baseOpacity = item.layer.opacity || 1;
            if (item.layer.hasOwnProperty("opacity")) {
                slider = new Slider({
                    label: "Gjennomsiktighet",
                    visible: true,
                    min: 0,
                    max: 1,
                    precision: 2,
                    values: [baseOpacity],
                    visibleElements: {
                        labels: true,
                        rangeLabels: true,
                    },
                });

                slider.on("thumb-drag", (event: any) => {
                    const { value } = event;
                    item.layer.opacity = value;
                });

                item.panel = {
                    content: slider,
                    icon: "transparency",
                    open: false,
                    visible: true,
                } as LayerListItemPanel;
            }
        }
    };

    const toggleMeasureTool = () => {
        dispatch(setMeasureToolOpen(!measurementToolOpen));
    };

    const toggleLayerList = () => {
        console.log("toggleLayerList", !layerListOpen);
        dispatch(setLayerListOpen(!layerListOpen));
    };

    useEffect(() => {
        if (layerListOpen) {
            document.getElementsByClassName("esri-layer-list")[0]?.classList.add("layer-list-visible");
        } else {
            document.getElementsByClassName("esri-layer-list")[0]?.classList.remove("layer-list-visible");
        }
    }, [layerListOpen]);

    useEffect(() => {
        intl.setLocale("nb");

        const vectorMap = new Basemap({
            baseLayers: [
                new VectorTileLayer({
                    url: "https://services.geodataonline.no/arcgis/rest/services/GeocacheVector/GeocacheNordenBasisTerreng_WM/VectorTileServer",
                }),
            ],
        });

        const map = new WebMap({
            // portalItem: {
            //     portal: {
            //         url: AppConfig.ArcGIS.BaseUrl + AppConfig.ArcGIS.PortalUrl
            //     },
            //     id: "b5ad90f817d245168f71180401f6b3d1",
            // },
            basemap: vectorMap,
        });

        for (const configLayer of AppConfig.Layers) {
            const layer = new FeatureLayer({
                url: configLayer.Url,
                id: configLayer.Name,
                // labelingInfo: [labelClass],
                popupEnabled: true,
                // popupTemplate: {
                //   title: "{navn}",
                //   content: "{Beskrivelse}",
                // },
                outFields: ["*"],
                // popupTemplate: {
                //   title: "{navn}",
                //   content: "{Beskrivelse}",
                // },
            });

            esriHandleRef.current.push(
                reactiveUtils.watch(
                    () => layer.gdbVersion,
                    (gdbVersion) => {
                        console.log(gdbVersion);
                        setLayerVersion(layer.gdbVersion);
                    }
                )
            );

            map.add(layer);
        }
        let peilelinjalLayer = map.findLayerById("peilelinjal") as GraphicsLayer;
        if (!peilelinjalLayer) {
            peilelinjalLayer = new GraphicsLayer({ id: "peilelinjal", listMode: "hide" });
            map.add(peilelinjalLayer);
        }

        setWebMap(map);

        return () => {
            map?.destroy();
            esriHandleRef.current.forEach((handle) => handle.remove());
        };
    }, []);

    return (
        <>
            {webMap && (
                <ArcgisMap
                    onArcgisViewReadyChange={handleViewReady}
                    constraints={{ snapToZoom: false, rotationEnabled: false }}
                    map={webMap}
                    // popup={new Popup()}
                    // popupDisabled={false}
                    popup={
                        new Popup({
                            defaultPopupTemplateEnabled: true,
                            actions: [{ id: "open", title: "Åpne", type: "button" }],
                            // view: view,
                            // content: popupRoot,
                            // visibleElements: {
                            //   actionBar: false,
                            //   closeButton: false,
                            //   collapseButton: false,
                            //   featureNavigation: false,
                            //   featureListLayerTitle: false,
                            //   heading: false,
                            // },
                            dockEnabled: false,
                            dockOptions: {
                                buttonEnabled: false,
                            },
                        })
                    }
                >
                    <ArcgisPlacement position="top-right">
                        <div className="flex-col small-gap no-esri-shadow">
                            <NfsButton round inverted shadow active={measurementToolOpen} onClick={toggleMeasureTool}>
                                <RulerIcon />
                            </NfsButton>
                            <NfsButton round inverted shadow active={layerListOpen} onClick={toggleLayerList}>
                                <LayerListIcon />
                            </NfsButton>
                        </div>
                    </ArcgisPlacement>
                    <ArcgisExpand position="top-right">
                        <ArcgisEditor></ArcgisEditor>
                    </ArcgisExpand>
                    <ArcgisExpand position="bottom-left">
                        <ArcgisLegend></ArcgisLegend>
                    </ArcgisExpand>
                    {/* <ArcgisExpand position="top-left"> */}
                    <ArcgisLayerList
                        position="top-left"
                        dragEnabled
                        listItemCreatedFunction={layerListRenderFunction}
                        visibilityAppearance="checkbox"
                    ></ArcgisLayerList>
                    {/* </ArcgisExpand> */}
                    <ArcgisZoom position="bottom-right"></ArcgisZoom>
                    <ArcgisLocate position="bottom-right"></ArcgisLocate>
                    <ArcgisMeasurement position="bottom-right"></ArcgisMeasurement>
                    <ArcgisPlacement position="bottom-left">
                        <Scale></Scale>
                    </ArcgisPlacement>
                    <ArcgisScaleBar position="bottom-right"></ArcgisScaleBar>
                </ArcgisMap>
            )}
            {/* <ArcgisMap item-id="d5dda743788a4b0688fe48f43ae7beb9">
                <ArcgisSearch position="top-right" />
                <ArcgisLegend position="bottom-left" />
                <ArcgisBasemapToggle position="bottom-right" />
            </ArcgisMap> */}
            {versionContext?.showVersionMgmt.value && (
                <RenderInWindow onClose={() => versionContext.showVersionMgmt.set(false)}>
                    <VersionManagement />
                </RenderInWindow>
            )}
            {/* </div> */}
            <PopupPortal mount={popupRoot}>
                <SeamarkPopup mapView={arcgisMapRef.current?.view!} />
            </PopupPortal>
        </>
    );
};

export default MapControl;
