import * as React from "react";
const SvgPeilelinjal = (props) => /* @__PURE__ */ React.createElement("svg", { width: 20, height: 20, viewBox: "0 0 20 20", fill: "none", id: "svg1", "sodipodi:docname": "peilelinjal.svg", "inkscape:version": "1.4 (86a8ad7, 2024-10-11)", "xmlns:inkscape": "http://www.inkscape.org/namespaces/inkscape", "xmlns:sodipodi": "http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd", xmlns: "http://www.w3.org/2000/svg", "xmlns:svg": "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("defs", { id: "defs1" }), /* @__PURE__ */ React.createElement("sodipodi:namedview", { id: "namedview1", pagecolor: "#505050", bordercolor: "#eeeeee", borderopacity: 1, "inkscape:showpageshadow": 0, "inkscape:pageopacity": 0, "inkscape:pagecheckerboard": 0, "inkscape:deskcolor": "#505050", "inkscape:zoom": 14.707821, "inkscape:cx": 8.1249289, "inkscape:cy": 8.7028527, "inkscape:window-width": 1440, "inkscape:window-height": 830, "inkscape:window-x": 401, "inkscape:window-y": 1193, "inkscape:window-maximized": 1, "inkscape:current-layer": "svg1" }), /* @__PURE__ */ React.createElement("circle", { style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 2,
  strokeMiterlimit: 5.9,
  strokeDasharray: "none",
  strokeOpacity: 1
}, id: "path1", cx: 9.8798084, cy: 9.9759617, r: 8.2417679 }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeMiterlimit: 5.9,
  strokeDasharray: "none",
  strokeOpacity: 1
}, d: "M 9.9805764,10.278268 V 1.7129153", id: "path2" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeMiterlimit: 5.9,
  strokeDasharray: "0.75,0.75",
  strokeDashoffset: 0,
  strokeOpacity: 1
}, d: "m 9.651607,9.885653 7.610992,3.606759", id: "path3" }), /* @__PURE__ */ React.createElement("circle", { style: {
  fill: "currentColor",
  fillOpacity: 1,
  stroke: "currentColor",
  strokeWidth: 0.5,
  strokeMiterlimit: 5.9,
  strokeDasharray: "none",
  strokeDashoffset: 0,
  strokeOpacity: 1
}, id: "path4-8", cx: 17.336538, cy: 13.5, r: 1.2798408 }), /* @__PURE__ */ React.createElement("circle", { style: {
  fill: "currentColor",
  fillOpacity: 1,
  stroke: "currentColor",
  strokeWidth: 0.5,
  strokeMiterlimit: 5.9,
  strokeDasharray: "none",
  strokeDashoffset: 0,
  strokeOpacity: 1
}, id: "path4-8-5", cx: 9.962534, cy: 10.020226, r: 1.2798408 }));
export default SvgPeilelinjal;
