import * as geometryEngine from "@arcgis/core/geometry/geometryEngine";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

export const GetCodedValueAndAlias = (layer: any, fieldName: string, fieldValue: number | string) => {
    let returnValue = [fieldName, fieldValue];
    if (!layer?.fields) return returnValue;
    layer.fields.forEach((fld: any) => {
        if (fld.name === fieldName) {
            returnValue[0] = fld?.alias;
            const cDomain = fld?.domain;
            if (cDomain)
                cDomain.codedValues.forEach((cVal: any) => {
                    if (cVal.code === fieldValue) returnValue[1] = cVal.name;
                });
        }
    });
    return returnValue;
};

export const IsFeatureInMapExtent = (feature: __esri.Graphic) => {
    const map = document.getElementsByTagName("arcgis-map")[0];
    if (!map) return false;

    const inExtent = geometryEngine.contains(map.extent, feature.geometry);

    return inExtent;
};

export const getMapView = () => {
    const map = document.getElementsByTagName("arcgis-map")[0];
    if (!map) {
        return;
    }
    const mapview = map.view;
    if (!mapview) {
        return;
    }
    return mapview;
};

export const highlightFeature = async (feature: __esri.Graphic): Promise<IHandle | void> => {
    const map = document.getElementsByTagName("arcgis-map")[0];
    if (!map) return;
    if (feature?.layer instanceof FeatureLayer) {
        return await map.view.whenLayerView(feature.layer).then((layerView) => {
            return layerView.highlight(feature);
        });
    }
};

export const updateFeatureAttributes = async (updatedFeature: __esri.Graphic): Promise<boolean> => {
    const success = false;
    const result = await (updatedFeature.layer as FeatureLayer).applyEdits({ updateFeatures: [updatedFeature] });

    if (result.updateFeatureResults[0].error) {
        console.error("Error updating feature: ", result.updateFeatureResults[0].error);
    } else {
        console.log("Feature updated successfully");
        return true;
    }

    return success;
};