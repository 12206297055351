import { createSlice } from "@reduxjs/toolkit";

interface SearchState {
    searchResults: any[];
}

const initialState: SearchState = {
    searchResults: [],
};

const searchSlice = createSlice({
    name: "search",
    initialState,
    reducers: {
        setSearchResults(state, action) {
            state.searchResults = action.payload;
        },
    },
});

export default searchSlice.reducer;
export const {
    setSearchResults
} = searchSlice.actions;
export type { SearchState };
