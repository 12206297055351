import * as signalR from "@microsoft/signalr";
import { AppConfig } from "../AppConfig";
import { getAGEToken } from "./authenticate";
const URL = AppConfig.NFSAPI.BaseApiUrl + 'nfshub';
class Connector {
    public connection: signalR.HubConnection;
    static instance: Connector;
    public status: boolean = true;
    constructor(userId: string) {
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(URL, { accessTokenFactory: getAGEToken, headers: { UserId: userId } })
            .withAutomaticReconnect()
            .build();
        this.connection.start().catch(err => { console.log(err); this.status = false });
    }
    public static getInstance(userId: string): Connector {
        if (!Connector.instance)
            Connector.instance = new Connector(userId);
        return Connector.instance;
    }
}

export default Connector.getInstance;