import Circle from "@arcgis/core/geometry/Circle";
import Point from "@arcgis/core/geometry/Point";
import Polyline from "@arcgis/core/geometry/Polyline";
import Graphic from "@arcgis/core/Graphic";
import LineSymbolMarker from "@arcgis/core/symbols/LineSymbolMarker";
import SimpleLineSymbol from "@arcgis/core/symbols/SimpleLineSymbol";
import TextSymbol from "@arcgis/core/symbols/TextSymbol";
import MapView from "@arcgis/core/views/MapView";
import * as geometryEngine from "@arcgis/core/geometry/geometryEngine";
import { AppConfig } from "../../../AppConfig";
import { Unit } from "./measurementEnums";

const computeConvergenceAngle = (point: __esri.Point | null): number => {
    if (!point) return 0;

    const zeroMeridian = (AppConfig.Projection.CenterMeridian * Math.PI) / 180;
    const latitude = (point.latitude * Math.PI) / 180;
    const longitude = (point.longitude * Math.PI) / 180;

    const convergenceAngle = Math.atan(Math.tan(zeroMeridian - longitude) * Math.sin(latitude));
    const convergenceAngleDegrees = -(convergenceAngle * 180) / Math.PI;
    return convergenceAngleDegrees;
};

const createLine = (vertices: number[][], mapview: MapView) => {
    const polyline = {
        type: "polyline", // autocasts as new Polyline()
        paths: [vertices],
        spatialReference: mapview.spatialReference,
    };
    return polyline as Polyline;
};

export const drawPeilelinjal = (
    vertices: number[][],
    setCurrentMeasures: Function,
    mapview: MapView,
    unit: string | null
) => {
    mapview.graphics.removeAll();

    const line = createLine(vertices, mapview);
    const lineLengthMeters = geometryEngine.geodesicLength(line, "meters");
    const lineLengthMetersPlanar = geometryEngine.planarLength(line, "meters");
    const lineLengthNauticalMiles = geometryEngine.geodesicLength(line, "nautical-miles");
    // }
    const lineGraphic = new Graphic({
        geometry: line,
        symbol: new SimpleLineSymbol({
            color: [4, 90, 141],
            width: 2,
            style: "dash",
            marker: new LineSymbolMarker({
                style: "circle",
                placement: "begin-end",
                color: [4, 90, 141],
            }),
        }),
    });

    const circle = new Circle({
        center: new Point({
            x: vertices[0][0],
            y: vertices[0][1],
            spatialReference: mapview.spatialReference,
        }),
        radius: lineLengthMeters,
        radiusUnit: "meters",
        geodesic: true,
    });
    const circleGraphic = new Graphic({
        geometry: circle,
        symbol: new SimpleLineSymbol({
            color: [4, 90, 141],
            width: 2,
        }),
    });

    const gridNorthLine = new Polyline({
        paths: [
            [
                [vertices[0][0], vertices[0][1]],
                [vertices[0][0], vertices[0][1] + lineLengthMetersPlanar],
            ],
        ],
        spatialReference: mapview.spatialReference,
    });
    const trueNorthLine = geometryEngine.rotate(gridNorthLine, computeConvergenceAngle(circle.center), circle.center);
    const northLineGraphic = new Graphic({
        geometry: trueNorthLine,
        symbol: new SimpleLineSymbol({
            color: [4, 90, 141],
            width: 2,
        }),
    });

    mapview.graphics.addMany([lineGraphic, circleGraphic, northLineGraphic]);

    if (vertices.length < 2) return;

    const angleDrawnLine =
        Math.atan2(vertices[1][1] - vertices[0][1], vertices[1][0] - vertices[0][0]) * (180 / Math.PI);
    const angleTrueNorthLine =
        Math.atan2(
            (trueNorthLine as Polyline).paths[0][1][1] - (trueNorthLine as Polyline).paths[0][0][1],
            (trueNorthLine as Polyline).paths[0][1][0] - (trueNorthLine as Polyline).paths[0][0][0]
        ) *
        (180 / Math.PI);
    const angleBetweenLines = (angleTrueNorthLine - angleDrawnLine + 360) % 360;

    const cwAngleText = `${angleBetweenLines.toFixed(1)}°`;
    const ccwAngleText = `${(360 - angleBetweenLines).toFixed(1)}°`;

    const labelCwSymbol = new TextSymbol({
        text: `Angle CW: ${cwAngleText}\nAngle CCW: ${ccwAngleText}`,
        color: "black",
        font: {
            size: 12,
            weight: "bold",
        },
        verticalAlignment: "bottom",
        horizontalAlignment: "center",
        yoffset: 8,
    });

    const trueNorthPoint = new Point({
        x: (trueNorthLine as Polyline).paths[0][1][0],
        y: (trueNorthLine as Polyline).paths[0][1][1],
        spatialReference: mapview.spatialReference,
    });

    const labelCw = new Graphic({
        geometry: trueNorthPoint,
        symbol: labelCwSymbol,
    });

    const textAngle = -Math.atan2(vertices[1][1] - vertices[0][1], vertices[1][0] - vertices[0][0]) * (180 / Math.PI);
    const xoffset = Math.sin((textAngle / 180) * Math.PI) * 12;
    const yoffset = Math.cos((textAngle / 180) * Math.PI) * 8;
    const angleBreakPointCondition = textAngle >= 90 || textAngle <= -90;
    const labelLengthMetersText =
        lineLengthMeters < 1000 ? `${lineLengthMeters.toFixed(2)} m` : `${(lineLengthMeters / 1000).toFixed(2)} km`;
    const labelLengthNauticalMilesText = `${lineLengthNauticalMiles.toFixed(2)} nm`;

    const lengthText = unit === Unit.Meter ? labelLengthMetersText : labelLengthNauticalMilesText;

    const labelLengthSymbol = new TextSymbol({
        text: lengthText,
        color: "black",
        font: {
            size: 12,
            weight: "bold",
        },
        verticalAlignment: "bottom",
        horizontalAlignment: "center",
        rotated: true,
        angle: angleBreakPointCondition ? textAngle + 180 : textAngle,
        xoffset: angleBreakPointCondition ? -xoffset : xoffset,
        yoffset: angleBreakPointCondition ? -yoffset : yoffset,
    });

    const labelLengthPoint = new Point({
        x: (vertices[0][0] + vertices[1][0]) / 2,
        y: (vertices[0][1] + vertices[1][1]) / 2,
        spatialReference: mapview.spatialReference,
    });

    const labelLength = new Graphic({
        geometry: labelLengthPoint,
        symbol: labelLengthSymbol,
        attributes: {
            type: "lengthLabel",
            lengthMeters: lineLengthMeters,
            lengthNauticalMiles: lineLengthNauticalMiles,
        },
    });

    mapview.graphics.addMany([labelCw, labelLength]);

    setCurrentMeasures([
        { key: "Lengde", value: lengthText },
        { key: "Angle CW", value: cwAngleText },
        { key: "Angle CCW", value: ccwAngleText },
    ]);
};
