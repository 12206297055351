import { createSlice } from "@reduxjs/toolkit";

interface ScaleState {
    userDefinedScales: number[];
}

const initialState: ScaleState = {
    userDefinedScales: [],
};

const scaleSlice = createSlice({
    name: "scale",
    initialState,
    reducers: {
        setUserDefinedScales(state, action) {
            state.userDefinedScales = action.payload;
        },
        addUserDefinedScale(state, action) {
            state.userDefinedScales.push(action.payload);
        },
        removeUserDefinedScale(state, action) {
            state.userDefinedScales = state.userDefinedScales.filter((scale) => scale !== action.payload);
        },
    },
});

export default scaleSlice.reducer;
export const { setUserDefinedScales, addUserDefinedScale, removeUserDefinedScale } = scaleSlice.actions;
export type { ScaleState };
