export interface ArcGISConfig {
    BaseUrl: string;
    PortalUrl: string;
    AppClientId: string;
    AppRedirectUrl: string;
    SyncDB: string;
    VersionManagementService: string;
}

export interface NFSAPIConfig {
    BaseApiUrl: string;
}

export interface FDVConfig {
    BaseUrl: string;
    ObjectPropertiesBase: string;
}

export interface BasemapConfig {
    Name: string;
    ServiceUrl: string;
    ThumbnailUri: string;
}

export interface AppConfigInterface {
    Environment: string;
    Version: string;
    NFSAPI: NFSAPIConfig;
    FDV: FDVConfig;
    ArcGIS: ArcGISConfig;
    Basemaps: BasemapConfig[];
    Layers: LayersConfig[];
    Scale: ScalesConfig;
    Projection: ProjectionConfig;
}

export const AppConfig: AppConfigInterface = {
    ...window.NFSLAND_CONFIG,
};

export interface LayersConfig {
    Name: string;
    Url: string;
    zoomScale: number;
    SearchFields: string[];
    TitleField: string;
    ImportantFields: string[];
    HighlightedFields: string[];
    StatusField: string;
    ExcludeFields: string[];
}

export interface ScalesConfig {
    Scales: number[];
    DefaultScale: number;
    MinScale: number;
    MaxScale: number;
}

export interface ProjectionConfig {
    CenterMeridian: number;
}
