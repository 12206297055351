import React, { useMemo } from "react";
import "./App.css";
import "@arcgis/core/assets/esri/themes/light/main.css";
import { UserContext, UserContextType } from "./Context";
import { AuthenticatedContent } from "./components/AuthenticatedContent/AuthenticatedContent";
import { LoginScreen } from "./components/Login/Login";

import { defineCustomElements } from "@arcgis/map-components/dist/loader";
defineCustomElements(window, { resourcesUrl: "https://js.arcgis.com/map-components/4.31/assets" });

function App() {
  const [user, setUser] = React.useState<__esri.PortalUser>();
  const userContextStore = {
    user: {
      set: setUser,
      value: user,
    },
  } as UserContextType;

  const memoStore = useMemo(() => userContextStore, [userContextStore]);

  return (
    <UserContext.Provider value={memoStore}>
      {memoStore.user.value ? <AuthenticatedContent /> : <LoginScreen />}
    </UserContext.Provider>
  );
}

export default App;
