import { Popover } from "react-tiny-popover";
import "./NfsDropdown.css";
import { useState } from "react";
import ArrowDownIcon from "../../../assets/arrow-down.svg?react";

interface NfsDropdownProps {
    options: string[];
    selectedOption: string | null;
    setSelectedOption: (option: string | null) => void;
    placeholder: string;
    allowEmpty?: boolean;
    displayValueFunction?: (option: number | string) => string | number;
}

const NfsDropdown = (props: NfsDropdownProps) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Popover
            isOpen={isOpen}
            onClickOutside={() => setIsOpen(false)}
            positions={["bottom", "top"]}
            align="start"
            containerClassName="nfs-dropdown-panel"
            content={
                <>
                    {props.allowEmpty && props.selectedOption !== null && (
                        <button
                            className="nfs-dropdown-option"
                            onClick={() => {
                                props.setSelectedOption(null);
                                setIsOpen(false);
                            }}
                        >
                            {props.placeholder}
                        </button>
                    )}
                    {props.options.map((option) => {
                        if (option === props.selectedOption) {
                            return null;
                        }
                        return (
                            <button
                                key={option}
                                className="nfs-dropdown-option"
                                onClick={() => {
                                    props.setSelectedOption(option);
                                    setIsOpen(false);
                                }}
                            >
                                {!props.displayValueFunction &&
                                    option
                                }
                                {props.displayValueFunction &&
                                    props.displayValueFunction(option)
                                }

                            </button>
                        );
                    })}
                </>
            }
        >
            <button className="nfs-dropdown" onClick={() => setIsOpen(!isOpen)}>
                <span className="nfs-dropdown-text">
                    {props.selectedOption !== null ? <>
                        {!props.displayValueFunction &&
                            props.selectedOption
                        }
                        {props.displayValueFunction &&
                            props.displayValueFunction(props.selectedOption)
                        }
                    </>
                        : props.placeholder}
                </span>
                <ArrowDownIcon />
            </button>
        </Popover>
    );
};

export default NfsDropdown;
