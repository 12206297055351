import { createSlice } from "@reduxjs/toolkit";

interface VersionState {
    activeSessionID: string;
    activeGuid: string;
    readActive: boolean;
    editActive: boolean;
}

const initialState: VersionState = {
    activeSessionID: "",
    activeGuid: "",
    readActive: false,
    editActive: false,
};

const versionSlice = createSlice({
    name: "version",
    initialState,
    reducers: {
        setActiveSessionID(state, action) {
            state.activeSessionID = action.payload;
        },
        setActiveGuid(state, action) {
            state.activeGuid = action.payload;
        },
        setReadActive(state, action) {
            state.readActive = action.payload;
        },
        setEditActive(state, action) {
            state.editActive = action.payload;
        },
    },
});

export default versionSlice.reducer;
export const {
    setActiveSessionID,
    setActiveGuid,
    setReadActive,
    setEditActive
} = versionSlice.actions;
export type { VersionState };
