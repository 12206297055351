export interface ISeamarkPopupProps {
  mapView: __esri.MapView;
}

export function SeamarkPopup(props: ISeamarkPopupProps) {
  const handleCloseClick = () => {
    props.mapView.popup.close();
  };

  return (
    <div>
      <div>This is a seamark popup</div>
      <button className="primary" onClick={handleCloseClick}>
        Close
      </button>
    </div>
  );
}
