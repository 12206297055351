import * as React from "react";
import { signOut } from "../../utils/authenticate";
import AccountIcon from "../../assets/account-icon.svg?react";
import LogoutIcon from "../../assets/logout-icon.svg?react";
import "./UserAccount.css";
import { UserContext } from "../../Context";

export function UserAccount() {
  const userContext = React.useContext(UserContext);
  const userName = userContext?.user.value?.fullName;

  const onLogout = () => {
    signOut();
    userContext?.user.set(null);
  };

  return (
    <div className="account-container">
      <div className="account-wrapper">
        <span className="account-username">{userName}</span>
        <AccountIcon />
      </div>
      <div className="account-menu">
        <button className="account-logout" onClick={onLogout} title="Logg ut">
          <span>Logg ut</span>
          <LogoutIcon />
        </button>
      </div>
    </div>
  );
}
