

import Layer from "@arcgis/core/layers/Layer";
import "./FeatureField.css";
import { GetCodedValueAndAlias } from "../../utils/arcgisUtils";
import { formatDate } from "../../utils/helpers";
import NfsDropdown from "../genericComponents/NfsDropdown/NfsDropdown";
import InputField from "../genericComponents/InputField/InputField";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import { readOnlyFields } from "../../constants/NauticalObjects";

interface FeatureFieldProps {
    isEditing: boolean;
    onChange: (value: any) => void;
    field: string;
    layer: Layer;
    attributes: __esri.Graphic["attributes"];
}

const FeatureField = (props: FeatureFieldProps) => {

    const codedValue = GetCodedValueAndAlias(props.layer, props.field, props.attributes[props.field])[1];

    const renderEditableField = () => {

        if (readOnlyFields.includes(props.field)) {
            return (
                renderField()
            )
        }

        if (!props.layer.hasOwnProperty("fields")) {
            return (
                renderField()
            );
        }

        const fieldInfo = (props.layer as FeatureLayer)?.fields.find((field) => field.name === props.field);

        if (!fieldInfo) {
            return (
                renderField()
            );
        }

        if (fieldInfo.editable === false) {
            return (
                renderField()
            );
        }

        if (fieldInfo.hasOwnProperty("domain") && fieldInfo.domain !== null && fieldInfo.domain.type === "coded-value") {
            const domainOptions = fieldInfo.domain.codedValues.map((codedValue: { name: string, code: any }) => codedValue.code);

            return (
                <NfsDropdown options={domainOptions} selectedOption={props.attributes[props.field]} displayValueFunction={(value: number | string) => GetCodedValueAndAlias(props.layer, props.field, value)[1]} setSelectedOption={(value) => { props.onChange(value) }} placeholder={"Ikke definert"} allowEmpty={fieldInfo.nullable} />
            )
        }

        if (fieldInfo.type === "string") {
            return (
                <InputField inputFieldId={props.field} label="" value={props.attributes[props.field] ?? ""} type="text" onChange={(value) => { props.onChange(value) }} />
            )
        }

        if (fieldInfo.type === "double") {
            return (
                <InputField inputFieldId={props.field} label="" value={props.attributes[props.field] ?? ""} type="number" onChange={(value) => { props.onChange(value) }} />
            )
        }

        if (fieldInfo.type === "integer") {
            return (
                <InputField inputFieldId={props.field} label="" value={props.attributes[props.field] ?? ""} type="number" onChange={(value) => { props.onChange(value) }} />
            )
        }

        return (
            <span>
                editing field
            </span>
        )
    }

    const renderField = () => {
        if (!props.layer.hasOwnProperty("fields")) {
            return (<span className="feature-viewer-value text-right">{codedValue}</span>);
        }

        const fieldInfo = (props.layer as FeatureLayer)?.fields.find((field) => field.name === props.field);

        if (!fieldInfo) {
            return (<span className="feature-viewer-value text-right">{codedValue}</span>);
        }

        if (fieldInfo.type === "date") {
            return (<span className="feature-viewer-value text-right">{formatDate(codedValue as number)}</span>);
        }

        return (<span className="feature-viewer-value text-right">{codedValue}</span>);
    }

    return (
        <div>
            {!props.isEditing &&
                <span className="feature-viewer-value text-right">{codedValue}</span>
            }
            {props.isEditing &&
                renderEditableField()
            }
        </div>
    );
}

export default FeatureField;