import { combineReducers } from "redux";
import app, { AppState } from "./appSlice";
import version, { VersionState } from "./versionSlice";
import search, { SearchState } from "./searchSlice";
import scale, { ScaleState } from "./scaleSlice";

const rootReducer = combineReducers({
    app,
    version,
    search,
    scale,
});

export type StoreState = {
    app: AppState;
    version: VersionState;
    search: SearchState;
    scale: ScaleState;
};

export default rootReducer;
