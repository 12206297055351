import React from "react";

export interface UserContextType {
  user: {
    set: (value: __esri.PortalUser | null) => void;
    value: __esri.PortalUser | null;
  };
}

export interface VersionContextType {
  currentVersion: {
    set: (value: __esri.VersionInfoJSON) => void;
    value: __esri.VersionInfoJSON;
  };
  mapView: {
    set: (value: __esri.MapView) => void;
    value: __esri.MapView;
  };
  showVersionMgmt: {
    set: (value: boolean) => void;
    value: boolean;
  };
}

export interface AppContextType {
  selectedFeature: {
    set: (value: __esri.Graphic | null) => void;
    value: __esri.Graphic | null;
  };
}

export interface MenuContextType { }

export const UserContext = React.createContext<UserContextType | undefined>(undefined);
export const VersionContext = React.createContext<VersionContextType | undefined>(undefined);
export const MenuContext = React.createContext<MenuContextType | undefined>(undefined);
export const AppContext = React.createContext<AppContextType | undefined>(undefined);