import { useEffect } from "react";

/**
 * useClickedOutside Hook
 * @usage : useClickedOutside(ref, () => setIsOpen(false));
 * @description : Custom hook used for components to handle when user clicked outside the ref parameter.
 * @parameters : ref is a reference to the JSX element of the component.
 * onClickOutside is the function we want to call when clicked outside given component.
 */
export function useClickedOutside(ref: React.RefObject<HTMLDivElement>, onClickOutside: () => void) {
    useEffect(() => {
        function handleClickedOutside(event: MouseEvent): void {
            // trigger the provided function if this element was not clicked
            if (ref.current && !ref.current.contains(event.target as Node)) {
                onClickOutside();
            }
        }
        document.addEventListener("mousedown", handleClickedOutside);
        return () => {
            // clean up
            document.removeEventListener("mousedown", handleClickedOutside);
        };
    });
}

export const uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};

/**
 * Takes and object and optional zoom level to zoom to the object on the map.
 * @param object
 * @param zoomLevel
 * @returns
 */
export const zoomToObject = (
    object: __esri.Graphic | __esri.Feature | __esri.Geometry,
    scale?: number,
    zoomLevel?: number
) => {
    const map = document.getElementsByTagName("arcgis-map")[0];
    if (!map) {
        return;
    }

    map.view.goTo(
        {
            target: object,
            scale: scale,
            zoom: zoomLevel,
        },
        {
            animate: true,
            duration: 1000,
            easing: "ease-in-out",
        }
    );
};

export const formatDate = (timestamp: number) => {
    if (!timestamp) {
        return "";
    }
    const date = new Date(timestamp);
    return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
};

export const isNumber = (value: string) => {
    return !isNaN(Number(value));
};

export const formatScaleString = (scale: number) => {
    return `1:${scale.toFixed(0)}`;
};

export const parseScaleString = (scale: string) => {
    return parseInt(scale.replace("1:", ""));
};


export const metersToNauticalMiles = (meters: string) => {

    const parts = meters.split(" ");
    const unit = parts[parts.length - 1];

    meters = meters.replace(/[a-zA-Z\s]/g, '');
    const metersNumber = parseFloat(meters);
    if (isNaN(metersNumber)) {
        throw new Error("Invalid input: meters must be a number.");
    }


    if (unit === "nm") {
        return meters;
    }
    if (unit === "m") {
        return (metersNumber / 1852).toFixed(4).replace('.', ',') + " nm";
    }
    if (unit === "km") {
        return (metersNumber * 1000 / 1852).toFixed(4).replace('.', ',') + " nm";
    }
}