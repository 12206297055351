import VersionManagementService from "@arcgis/core/versionManagement/VersionManagementService.js";
import { AppConfig } from "../../AppConfig";
import React from "react";
import { VersionContext } from "../../Context";
import * as versionManagementAdapterUtils from "@arcgis/core/versionManagement/versionAdapters/utils.js";
import Collection from "@arcgis/core/core/Collection.js";
import "./VersionManagement.css";
import { findDifferences, getSessionID, startEditing, startReading, stopEditing, stopReading } from "../../utils/versioningUtils";
import { useDispatch, useSelector } from "react-redux";
import { setActiveGuid, setActiveSessionID, setEditActive, setReadActive } from "../../store/versionSlice";
import { StoreState } from "../../store/rootReducer";


export function VersionManagement() {
  const versionContext = React.useContext(VersionContext);

  const dispatch = useDispatch();

  const versionServiceRef = React.useRef(
    new VersionManagementService({
      url: AppConfig.ArcGIS.BaseUrl + AppConfig.ArcGIS.VersionManagementService,
    })
  );

  const newVersionNameRef = React.useRef<HTMLInputElement>(null);
  const versionNameToDeleteRef = React.useRef<HTMLInputElement>(null);

  const [versionInfos, setVersionInfos] = React.useState<__esri.VersionInfoJSON[]>();

  const activeSessionID = useSelector((state: StoreState) => state.version.activeSessionID);
  const activeGuid = useSelector((state: StoreState) => state.version.activeGuid);
  const readActive = useSelector((state: StoreState) => state.version.readActive);
  const editActive = useSelector((state: StoreState) => state.version.editActive);

  const reloadVersions = async () => {
    setVersionInfos(await versionServiceRef.current.getVersionInfos());
  };

  const loadService = async () => {
    await versionServiceRef.current.load();
    await reloadVersions();

    // handleSelectVersion(versionServiceRef.current.defaultVersionIdentifier);
  };

  const handleAddVersionClick = async () => {
    if (newVersionNameRef.current && newVersionNameRef.current?.value !== "") {
      try {
        const newVersionExtendedInfo = await versionServiceRef.current.createVersion({
          versionName: newVersionNameRef.current?.value,
          access: "public",
        } as __esri.VersionManagementServiceCreateVersionProps);
        await reloadVersions();
        newVersionNameRef.current.value = "";
        console.log(newVersionExtendedInfo);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleDeleteVersionClick = async () => {
    if (versionNameToDeleteRef.current && versionNameToDeleteRef.current?.value !== "") {
      const versionInfoToDelete = versionInfos?.find(
        (x) => x.versionIdentifier.name === versionNameToDeleteRef.current?.value
      );

      if (!versionInfoToDelete) {
        alert("Version info not found for this name");
        return;
      }

      try {
        await versionServiceRef.current.deleteVersion(versionInfoToDelete.versionIdentifier);
        await reloadVersions();
        versionNameToDeleteRef.current.value = "";
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleSelectVersion = async (vi: __esri.VersionInfoJSON) => {
    // if (versionContext && versionContext.mapView) {
    const map = versionContext?.mapView.value.map;
    let layers = map?.allLayers.toArray() as __esri.FeatureLayer[];
    layers = layers?.filter((l) => l.type === "feature");
    if (!layers) {
      return;
    }
    const adapters = versionManagementAdapterUtils.createVersionAdapters(layers);
    const adaptersCollection = new Collection();
    adaptersCollection.addMany(adapters);

    const currentVersionIdentifier =
      versionInfos?.find((x) => x.versionIdentifier.name === layers[0].gdbVersion)?.versionIdentifier ??
      versionServiceRef.current.defaultVersionIdentifier;

    const changeResult = await versionServiceRef.current.changeVersionWithResult(
      adaptersCollection,
      currentVersionIdentifier,
      vi.versionIdentifier
    );

    console.log("Change version result:");
    console.log(changeResult);

    versionContext?.currentVersion?.set(vi);

    let guid = versionContext?.currentVersion?.value?.versionIdentifier.guid;
    if (!guid) {
      return;
    }
    guid = guid.replace("{", "").replace("}", "");
    dispatch(setActiveGuid(guid));
    const sessionID = getSessionID();
    dispatch(setActiveSessionID(sessionID));
  };

  const handlePostVersion = async (versionInfoJson: __esri.VersionInfoJSON) => {
    const versionService = versionServiceRef.current;
    const vi = versionInfoJson.versionIdentifier;

    try {
      const startReadingResult = await versionService.startReadingWithResult(vi);
      console.log("Start reading result:", startReadingResult);

      const startEditingResult = await versionService.startEditingWithResult(vi);
      console.log("Start editing result:", startEditingResult);

      const reconcileResult = await versionService.reconcile(vi, {
        abortIfConflicts: true,
        conflictDetection: "by-object",
        withPost: true,
      });

      console.log("Reconcile result:");
      console.log(reconcileResult);

      const stopEditingResult = await versionService.stopEditingWithResult(vi, true);
      console.log("Stop editing result:", stopEditingResult);

      const stopReadingResult = await versionService.stopReadingWithResult(vi);
      console.log("Stop reading result:", stopReadingResult);
    } catch (e: any) {
      const stopEditingResult = await versionService.stopEditingWithResult(vi, false);
      console.log("Stop editing result:", stopEditingResult);

      const stopReadingResult = await versionService.stopReadingWithResult(vi);
      console.log("Stop reading result:", stopReadingResult);

      alert(e.message);
    }
  };

  // WIP
  // const showCurrentEdits = async () => {
  //   const sessionID = getSessionID();
  //   const guid = versionContext?.currentVersion?.value?.versionIdentifier.guid;
  //   if (!guid) {
  //     return;
  //   }
  //   await startReading(guid, sessionID);
  //   await startEditing(guid, sessionID);
  //   await reconcileVersion(guid, sessionID, false, "byObject", false);
  //   await getConflicts(guid, sessionID);

  //   await stopEditing(guid, sessionID, false);
  //   await stopReading(guid, sessionID);
  // };

  const startRead = async () => {

    await startReading(activeGuid, activeSessionID);
    dispatch(setReadActive(true));
  };

  const startEdit = async () => {

    if (!activeGuid) {
      return;
    }
    await startEditing(activeGuid, activeSessionID)
    dispatch(setEditActive(true));
  };

  const stopEdit = async (saveEdits: boolean) => {

    if (!activeGuid) {
      return;
    }
    await stopEditing(activeGuid, activeSessionID, saveEdits);
    dispatch(setEditActive(false));
  };

  const stopRead = async () => {

    if (!activeGuid) {
      return;
    }
    if (editActive) {
      await stopEditing(activeGuid, activeSessionID, false);
      dispatch(setEditActive(false));
    }
    await stopReading(activeGuid, activeSessionID)
    dispatch(setReadActive(false));
    dispatch(setActiveSessionID(""));
  };

  const fetchDiffs = async () => {
    const diffs = await findDifferences(activeGuid)
    console.log(diffs);
  };

  React.useEffect(() => {
    loadService();
  }, []);

  React.useEffect(() => {
    console.log("VersionManagementService.LoadStatus:", versionServiceRef.current.loadStatus);
    console.log(versionServiceRef.current);
    if (versionServiceRef.current.loadStatus === "failed") {
      console.log(versionServiceRef.current.loadError);
    } else if (versionServiceRef.current.loadStatus === "loaded") {
      if (!versionContext?.currentVersion.value) {
        console.log(versionServiceRef.current.defaultVersionIdentifier)

        handleSelectVersion({ versionIdentifier: versionServiceRef.current.defaultVersionIdentifier } as __esri.VersionInfoJSON);
      }
    }
  }, [versionServiceRef.current.loadStatus]);

  return (
    <div className="version-management-container">
      <div>
        <h2>Current version</h2>
        <p>{versionContext?.currentVersion?.value?.versionIdentifier.name ?? "none"}</p>
      </div>

      <h2>All versions</h2>

      {versionInfos && (
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Access</th>
              <th>Creation date</th>
              <th>Description</th>
            </tr>
          </thead>
          {versionInfos.map((vi) => {
            return (
              <tr key={vi.versionId}>
                <td>{vi.versionIdentifier.name}</td>
                <td>{vi.access}</td>
                <td>{new Date(vi.creationDate!).toISOString()}</td>
                <td>{vi.description}</td>
                <td style={{ display: "flex", flexDirection: "row", gap: "10px", border: "0px" }}>
                  {!readActive &&
                    <button className="primary" onClick={() => handleSelectVersion(vi)}>
                      Select
                    </button>
                  }
                  {vi.versionIdentifier.name === versionContext?.currentVersion?.value?.versionIdentifier.name &&
                    <>
                      <button className="secondary" onClick={() => handlePostVersion(vi)}>
                        Reconcile & Post
                      </button>
                      <button disabled={readActive} onClick={startRead}>
                        Start read
                      </button>
                      <button disabled={editActive || !readActive} onClick={startEdit}>
                        Start edit
                      </button>
                      <button disabled={!editActive} onClick={() => stopEdit(true)}>
                        Stop edit
                      </button>
                      <button disabled={!editActive} onClick={() => stopEdit(false)}>
                        Discard edit
                      </button>
                      <button disabled={!readActive || editActive} onClick={stopRead}>
                        Stop read
                      </button>
                      <button onClick={fetchDiffs}>
                        Test diffrences
                      </button>
                    </>
                  }
                </td>
              </tr>
            );
          })}
        </table>
      )}

      <div style={{ display: "flex", flexDirection: "row", gap: "30px" }}>
        <div>
          <h4>Add version</h4>
          <label htmlFor="newVersionName">New version name: </label>
          <input id="newVersionName" type="text" ref={newVersionNameRef} />
          <button className="primary" onClick={handleAddVersionClick}>
            Add
          </button>
        </div>
        <div>
          <h4>Delete version</h4>
          <label htmlFor="deleteVersionName">Name of version to delete: </label>
          <input id="deleteVersionName" type="text" ref={versionNameToDeleteRef} />
          <button className="primary" onClick={handleDeleteVersionClick}>
            Delete
          </button>
        </div>
      </div>
    </div>
  );
}
