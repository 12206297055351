import { useContext, useEffect, useRef, useState } from "react";
import LeftArrowIcon from "../../assets/arrow-left.svg?react";
import CloseIcon from "../../assets/close.svg?react";
import * as symbolUtils from "@arcgis/core/symbols/support/symbolUtils.js";

import "./FeatureViewer.css";
import { AppContext } from "../../Context";
import { GetCodedValueAndAlias, highlightFeature, updateFeatureAttributes } from "../../utils/arcgisUtils";
import NfsButton from "../genericComponents/NfsButton/NfsButton";
import { AppConfig, LayersConfig } from "../../AppConfig";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../store/rootReducer";
import { setFeatureViewerOpen } from "../../store/appSlice";
import FeatureField from "./FeatureField";

const FeatureViewer = () => {
    const dispatch = useDispatch();

    const [panelClass, setPanelClass] = useState<string>("collapsed");
    const [showAllAttributes, setShowAllAttributes] = useState<boolean>(false);
    const [symbolHtmlElement, setSymbolHtmlElement] = useState<HTMLElement | null>(null);

    const [isEditing, setIsEditing] = useState<boolean>(false);

    const featureViewerOpen = useSelector((state: StoreState) => state.app.featureViewerOpen);

    const layerConfig = useRef<LayersConfig>();
    const highlightRef = useRef<IHandle>();
    const unChangedFeature = useRef<__esri.Graphic>();

    const appContext = useContext(AppContext);
    const selectedFeature = appContext?.selectedFeature.value;

    // Due to css behavior with animations to display none and vice versa
    // an additional class is needed to allow panel to slide out
    const toggleOpen = () => {
        if (panelClass === "") {
            closePanel();
        } else if (panelClass === "collapsed") {
            openPanel();
        }
    };

    const openPanel = () => {
        if (panelClass === "") {
            return;
        }
        setPanelClass("opening");
        setTimeout(() => {
            setPanelClass("");
        }, 100);
    };

    const closePanel = () => {
        setPanelClass("collapsed");
        dispatch(setFeatureViewerOpen(false));
    };

    const unselectFeature = () => {
        closePanel();
        appContext?.selectedFeature.set(null);
    };

    const editFeatureAttribute = (field: string, value: any) => {
        if (!selectedFeature) {
            return;
        }
        const clonedFeature = selectedFeature.clone();
        clonedFeature.attributes[field] = value;

        appContext?.selectedFeature.set(clonedFeature);
    };

    const saveFeature = () => {
        if (!selectedFeature) {
            setIsEditing(false);
            return;
        }
        updateFeatureAttributes(selectedFeature).then((success: boolean) => {
            if (success) {
                setIsEditing(false);
            } else {
                console.log("Error saving feature");
            }
        });
    };

    const toggleEdit = (edit: boolean) => {
        setIsEditing(edit);
        if (!edit) {
            if (unChangedFeature.current) {
                appContext?.selectedFeature.set(unChangedFeature.current);
            }
        } else {
            unChangedFeature.current = selectedFeature?.clone();
        }
    };

    const openInFDV = (GISOBJID: number) => {
        window.open(`${AppConfig.FDV.BaseUrl}${AppConfig.FDV.ObjectPropertiesBase}${GISOBJID}`);
    };

    useEffect(() => {
        if (featureViewerOpen) {
            openPanel();
        } else {
            closePanel();
        }
    }, [featureViewerOpen]);

    useEffect(() => {
        if (selectedFeature) {
            highlightFeature(selectedFeature).then((handle) => {
                if (handle) {
                    highlightRef.current = handle;
                }
            });
            openPanel();
            layerConfig.current = AppConfig.Layers.find((layerConfig) => layerConfig.Name === selectedFeature.layer.id);
            symbolUtils.getDisplayedSymbol(selectedFeature).then((symbol) => {
                if (symbol) {
                    symbolUtils.renderPreviewHTML(symbol, { size: 12 }).then((symbolElement) => {
                        setSymbolHtmlElement(symbolElement);
                    });
                }
            });
        } else {
            setShowAllAttributes(false);
            setIsEditing(false);
            closePanel();
        }

        return () => {
            if (highlightRef.current) {
                highlightRef.current.remove();
            }
        };
    }, [selectedFeature]);

    useEffect(() => {
        return () => {
            if (highlightRef.current) {
                highlightRef.current.remove();
            }
        };
    }, []);

    if (!selectedFeature) {
        return null;
    }

    return (
        <div className="feature-viewer-container">
            <div className={`feature-viewer ${panelClass} ${isEditing ? "larger" : ""}`}>
                <div className="feature-viewer-header">
                    <span className="flex-row small-gap" style={{ width: "100%" }}>
                        <NfsButton
                            onClick={() => {
                                setShowAllAttributes(!showAllAttributes);
                            }}
                        >
                            {showAllAttributes ? "Vis færre" : "Vis mer"}
                        </NfsButton>
                        <NfsButton
                            onClick={() => {
                                toggleEdit(!isEditing);
                            }}
                        >
                            {isEditing ? "Avbryt" : "Rediger"}
                        </NfsButton>
                    </span>
                    <button className="empty-button" onClick={unselectFeature}>
                        <CloseIcon />
                    </button>
                </div>
                <div className="feature-viewer-content">
                    <div className="feature-viewer-feature-box">
                        <div className="feature-viewer-feature-title">
                            <div
                                className="search-item-symbol"
                                dangerouslySetInnerHTML={{ __html: symbolHtmlElement?.outerHTML ?? "" }}
                            ></div>
                            <span>
                                {selectedFeature.attributes["SjomerkeNr"] ?? selectedFeature.attributes["OBJECTID"]}
                            </span>
                            <span>{selectedFeature.attributes[layerConfig.current?.TitleField ?? "OBJECTID"]}</span>
                        </div>

                        <div className="feature-status-panel">
                            {
                                GetCodedValueAndAlias(
                                    selectedFeature.layer,
                                    layerConfig.current?.StatusField ?? "",
                                    selectedFeature.attributes[layerConfig.current?.StatusField ?? "status"]
                                )[1]
                            }
                        </div>
                        <div className="feature-viewer-feature-attributes">
                            {selectedFeature.attributes &&
                                Object.keys(selectedFeature.attributes).map((key) => {
                                    if (layerConfig.current?.ExcludeFields?.includes(key)) {
                                        return null;
                                    }
                                    if (!layerConfig.current?.ImportantFields.includes(key)) {
                                        return null;
                                    }
                                    const fieldname = GetCodedValueAndAlias(
                                        selectedFeature.layer,
                                        key,
                                        selectedFeature.attributes[key]
                                    )[0];
                                    return (
                                        <div key={key} className="feature-viewer-feature-attribute">
                                            <span className="feature-viewer-key text-left">{fieldname}</span>
                                            <FeatureField
                                                field={key}
                                                layer={selectedFeature.layer}
                                                attributes={selectedFeature.attributes}
                                                isEditing={isEditing}
                                                onChange={(value) => {
                                                    editFeatureAttribute(key, value);
                                                }}
                                            />
                                        </div>
                                    );
                                })}
                            {showAllAttributes &&
                                selectedFeature.attributes &&
                                Object.keys(selectedFeature.attributes).map((key) => {
                                    if (layerConfig.current?.ExcludeFields?.includes(key)) {
                                        return null;
                                    }
                                    if (layerConfig.current?.ImportantFields.includes(key)) {
                                        return null;
                                    }
                                    const fieldname = GetCodedValueAndAlias(
                                        selectedFeature.layer,
                                        key,
                                        selectedFeature.attributes[key]
                                    )[0];
                                    return (
                                        <div key={key} className="feature-viewer-feature-attribute">
                                            <span className="feature-viewer-key text-left">{fieldname}</span>
                                            <FeatureField
                                                field={key}
                                                layer={selectedFeature.layer}
                                                attributes={selectedFeature.attributes}
                                                isEditing={isEditing}
                                                onChange={(value) => {
                                                    editFeatureAttribute(key, value);
                                                }}
                                            />
                                        </div>
                                    );
                                })}
                            <div className="feature-viewer-footer"></div>
                        </div>
                    </div>
                </div>
                <div className="feature-viewer-footer">
                    {isEditing && <NfsButton onClick={saveFeature}>Lagre</NfsButton>}
                    {!isEditing &&
                        selectedFeature.attributes["GISOBJID"] &&
                        selectedFeature.attributes["GISOBJID"] > 0 && (
                            <NfsButton
                                onClick={() => {
                                    openInFDV(selectedFeature.attributes["GISOBJID"]);
                                }}
                            >
                                Åpne i FDV
                            </NfsButton>
                        )}
                </div>
            </div>
            <button className={`feature-viewer-collapse-button ${panelClass}`} onClick={toggleOpen}>
                <LeftArrowIcon />
            </button>
        </div>
    );
};

export default FeatureViewer;
